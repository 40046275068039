import styled, { css } from 'styled-components'
import { fsvar, mediaQueries, svar } from 'styles'

export const containerStyles = () => css`
  --gap: ${svar('15')};
  ${mediaQueries.tabletLandUp} {
    --max-width: 700px;

    max-width: var(--max-width);
    padding-right: 0;
    padding-left: 0;
  }
`

export const Header = styled.div`
  --gap: ${svar('6')};

  gap: var(--gap);
  display: flex;
  flex-direction: column;
`

export const InfoWrapper = styled.div`
  --flex-direction: column;
  --gap: ${svar('3')};
  align-items: center;
  display: flex;
  gap: var(--gap);
  align-self: start;
  flex-direction: var(--flex-direction);

  ${mediaQueries.tabletLandUp} {
    --flex-direction: row;
  }
`

export const ReadTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${svar('2')};
`

export const PublishedAt = styled.time`
  --font-size: ${fsvar('sm')};
  font-size: var(--font-size);
`

export const ReadTime = styled.p`
  --font-size: ${fsvar('sm')};
  font-size: var(--font-size);
`

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
`
