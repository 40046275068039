// import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Wrapper as ArticleWrapper } from 'layouts/ArticleLayout/styles'
import React from 'react'
import styled from 'styled-components'
import { mediaQueries, svar } from 'styles'
import { AuthorFragment } from 'types'

interface IAuthorProps {
  author: AuthorFragment
}

export default function Author({ author }: IAuthorProps) {
  const { name, avatar } = author!
  const imageData = avatar?.image?.childImageSharp?.gatsbyImageData
  const alt = avatar?.alt_text || ''
  const image = getImage(imageData)

  return (
    <Wrapper>
      <ImageWrapper>
        <GatsbyImage image={image!} alt={alt} />
      </ImageWrapper>
      <p>{name}</p>
    </Wrapper>
  )
}

const ImageWrapper = styled.div`
  ${ArticleWrapper} & {
    --width: 15%;
    width: var(--width);
  }
`

const Wrapper = styled.div`
  --gap: ${svar('2')};

  gap: var(--gap);
  display: flex;
  align-items: center;

  ${mediaQueries.tabletUp} {
    --gap: ${svar('4')};
  }
`
