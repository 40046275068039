import styled from 'styled-components'
import { cvar, fsvar, fwvar, mediaQueries, svar } from 'styles'

export const Content = styled.div`
  color: ${cvar('text-charcoal')};

  a {
    color: ${cvar('primary')};
    font-weight: ${fwvar('semibold')};

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    margin: ${svar('6')} 0 ${svar('6')} 0;
    padding-left: ${svar('4')};
    border-left: 0.3rem solid ${cvar('text-charcoal-60')};
  }

  em {
    font-style: italic;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 3rem 0 1.38rem;
    text-rendering: optimizeLegibility;
  }

  h2 {
    --font-size: ${fsvar('lg')};

    color: ${cvar('text-electric-blue')};
    font-size: var(--font-size);

    ${mediaQueries.tabletUp} {
      --font-size: ${fsvar('xl')};
    }

    ${mediaQueries.desktopUp} {
      --font-size: ${fsvar('2xl')};
    }
  }

  h3 {
    --font-size: ${fsvar('md')};

    font-size: var(--font-size);
    ${mediaQueries.desktopUp} {
      --default-font-size: ${fsvar('xl')};
    }
  }

  li::marker {
    color: ${cvar('text-charcoal-60')};
  }

  ol {
    list-style-type: decimal;
  }

  ol,
  ul {
    margin-left: 2.4rem;
  }

  p,
  ol,
  ul {
    margin-bottom: 1.6rem;
  }

  strong {
    font-weight: ${fwvar('bold')};
  }

  ul {
    list-style-type: disc;
  }
`
