/* eslint-disable react/no-danger */
import React from 'react'
import sanitizeHtml from 'sanitize-html'
import * as S from './styles'

export default function BlogContent({ data }: any) {
  return (
    <S.Content
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(data)
      }}
    />
  )
}
