/* eslint-disable no-underscore-dangle */
import Author from 'blog-components/Author'
import BlogContent from 'blog-components/BlogContent'
import Headline from 'components/Headline'
import SEO from 'components/SEO'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container from 'layouts/Container'
import React from 'react'
import { ArticleBySlugQuery, AuthorFragment } from 'types'
import * as S from './styles'

interface ArticleLayoutProps {
  article: ArticleBySlugQuery
}

export default function ArticleLayout({ article }: ArticleLayoutProps) {
  const { frontmatter, timeToRead, fields } = article.article!
  const image = getImage(
    frontmatter.cover?.image?.childImageSharp?.gatsbyImageData
  )

  const alt = frontmatter.cover?.alt_text || ''

  return (
    <S.Wrapper>
      <SEO
        isArticle
        twitterType="summary_large_image"
        title={frontmatter.title}
        description={frontmatter.description}
        pathname={`/blog${fields?.slug}`}
        datePublished={frontmatter.publishedAt}
        image={
          frontmatter.seo?.shareImage?.childImageSharp?.gatsbyImageData.images
            .fallback.src
        }
      />
      <Container styles={S.containerStyles}>
        <S.Header>
          <Headline>{frontmatter.title}</Headline>
          <S.InfoWrapper>
            <Author author={frontmatter.author as AuthorFragment} />
            <S.ReadTimeWrapper>
              <S.PublishedAt>{frontmatter.publishedAt}</S.PublishedAt>
              <S.ReadTime>{timeToRead} min read</S.ReadTime>
            </S.ReadTimeWrapper>
          </S.InfoWrapper>
        </S.Header>
        <GatsbyImage image={image!} alt={alt} />
        <BlogContent data={article.article?.html} />
      </Container>
    </S.Wrapper>
  )
}
