import { graphql } from 'gatsby'
import ArticleLayout from 'layouts/ArticleLayout'
import React from 'react'
import { ArticleBySlugQuery } from 'types'

interface IArticlePageProps {
  data: ArticleBySlugQuery
}

export default function ArticlePage({ data }: IArticlePageProps) {
  return <ArticleLayout article={data} />
}

export const query = graphql`
  query ArticleBySlug($id: String!) {
    article: markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...Frontmatter
      }
      timeToRead
      html
      fields {
        slug
      }
    }
  }
`
